/*
0 - 600px:      Phone
600 - 960px:    Tablet portrait
960 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
:root {
  --color-primary: #ffffff;
  --color-secondary: #000000;
  --font-family-primary: "Poppins", sans-serif;
  --size-primary: 4rem;
  --size-secondary: 2.4rem;
  --size-paragraph-one: 1.6rem;
  --size-paragraph-two: 1.2rem;
  --size-huge: 7.2rem;
  --nav-width: 10rem;
  --ticker-height: 7.2rem;
  --section-margin: 10rem;
  --title-margin: 4rem;
  --mini-title-margin: 1rem;
  --side-margin: 12rem;
  --bar-height: 8.9rem;
  --color-green: #069E2D;
  --color-green-dark: #036016;
  --color-green-neon: #CEFF1A;
  --color-cream: #FCFBF4;
  --color-light-blue: #C9DCB3;
  --color-medium-blue: #1098F7;
  --color-dark-blue: #233670;
  --color-mint-cream: #F2F7F2;
  --color-grey: #535657;
  --color-light-grey: #8A8E91;
  --color-logo-bg: #F2F0D9;
  --color-logo-red: #A3372D;
}
@media only screen and (max-width: 60em) {
  :root {
    --section-margin: 6rem;
    --side-margin: 4rem;
    --nav-width: 6rem;
    --size-primary: 2rem;
    --title-margin: 2rem;
    --size-paragraph-one: 1.4rem;
  }
}
@media only screen and (max-width: 37.5em) {
  :root {
    --side-margin: 2rem;
    --section-margin: 4rem;
    --bar-height: 6.4rem;
    --size-huge: 4.8rem;
    --size-primary: 1.8rem;
    --size-secondary: 1.6rem;
  }
}

[class*=heading] {
  font-family: var(--font-family-primary);
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
}

[class*=paragraph] {
  font-style: normal;
  font-family: var(--font-family-primary);
  line-height: 1.4;
}

.heading-primary {
  font-size: var(--size-primary);
}

.heading-secondary {
  font-size: var(--size-secondary);
}

.paragraph-one {
  font-size: var(--size-paragraph-one);
  font-weight: 400;
}

.paragraph-two {
  font-size: var(--size-paragraph-two);
  font-weight: 700;
}

.paragraph-caption {
  font-size: var(--size-paragraph-two);
  font-weight: 400;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  background-color: var(--color-dark-blue);
}

::selection {
  background-color: #000;
  color: var(--color-cream);
}

.u-margin-bottom-xs {
  margin-bottom: 1rem;
}

.u-margin-bottom-sm {
  margin-bottom: 2rem;
}

.u-margin-bottom-md {
  margin-bottom: 4rem;
}

.u-margin-bottom-lg {
  margin-bottom: 6rem;
}

.u-margin-bottom-xl {
  margin-bottom: 8rem;
}

.u-margin-top-sm {
  margin-top: 2rem;
}

.u-margin-top-md {
  margin-top: 4rem;
}

.u-margin-top-lg {
  margin-top: 6rem;
}

.u-margin-top-xl {
  margin-top: 8rem;
}

.u-center-text {
  text-align: center;
}

.article-markdown [class^=heading-] {
  margin-bottom: 3rem;
}
.article-markdown [class^=heading-].key {
  text-align: center;
}
.article-markdown p {
  text-align: justify;
}
.article-markdown p:not(:last-child) {
  margin-bottom: 3rem;
}
.article-markdown sup {
  position: relative;
}
.article-markdown sup::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 300%;
  width: 600%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.article-markdown a {
  text-decoration: none;
}
.article-markdown a:hover {
  color: #fff;
}
.article-markdown ul,
.article-markdown ol {
  margin-left: 2rem;
  margin-bottom: 3rem;
}
.article-markdown li:not(:last-child) {
  margin-bottom: 2rem;
}
.article-markdown blockquote {
  width: 90%;
  margin: 0 auto;
}
.article-markdown blockquote:not(:last-child) {
  margin-bottom: 3rem;
}
.article-markdown table {
  margin: 0 auto 3rem auto;
  width: 90%;
  font-size: 1.6rem;
  table-layout: fixed;
  border-spacing: 0px;
  border-collapse: collapse;
}
@media only screen and (max-width: 37.5em) {
  .article-markdown table {
    font-size: 1.4rem;
  }
}
.article-markdown table th {
  text-align: left;
  font-weight: 700;
}
.article-markdown table td,
.article-markdown table th {
  padding: 1rem;
}