@use 'mixins.scss' as m;

:root {
    --color-primary: #ffffff;
    --color-secondary: #000000;

    --font-family-primary: 'Poppins', sans-serif;

    --size-primary: 4rem;
    --size-secondary: 2.4rem;
    --size-paragraph-one: 1.6rem;
    --size-paragraph-two: 1.2rem;
    --size-huge: 7.2rem;

    --nav-width: 10rem;
    --ticker-height: 7.2rem;
    --section-margin: 10rem;
    --title-margin: 4rem;
    --mini-title-margin: 1rem;
    --side-margin: 12rem;
    --bar-height: 8.9rem;

    --color-green: #069E2D;
    --color-green-dark: #036016;
    --color-green-neon: #CEFF1A;
    --color-cream: #FCFBF4;
    --color-light-blue: #C9DCB3;
    --color-medium-blue: #1098F7;
    --color-dark-blue: #233670;
    --color-mint-cream: #F2F7F2;
    --color-grey: #535657;
    --color-light-grey: #8A8E91;
    --color-logo-bg: #F2F0D9;
    --color-logo-red: #A3372D;

    @include m.respond(tab-port) {
        --section-margin: 6rem;
        --side-margin: 4rem;
        --nav-width: 6rem;
        --size-primary: 2rem;
        --title-margin: 2rem;
        --size-paragraph-one: 1.4rem;
    }

    @include m.respond(phone) {
        --side-margin: 2rem;
        --section-margin: 4rem;
        --bar-height: 6.4rem;
        --size-huge: 4.8rem;
        --size-primary: 1.8rem;
        --size-secondary: 1.6rem;
    }
}

// for contrast ratio, it passes when color-yellow-bright
// is on color-blue-dark with higher font weight